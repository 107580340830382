














































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { AuditFlowScope, FundDtoPagedResultDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "FundAppropriateApply",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundAppropriateApply extends Vue {
  typeList: any = [];
  queryForm = {
    userName: "",
    surname: "",
    fundName: "",
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundAppropriateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundAppropriateApply.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "fundAppropriateApplyCreate",
    });
  }

  // 查看详情
  jumpDetail(id: number) {
    this.$router.push({
      name: "fundAppropriateApplyDetail",
      params: {
        id: id!.toString(),
      },
    });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundAppropriateApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
